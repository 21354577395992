import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  text-align: center;
`;

export const CodeWrapper = styled.div`
  width: 100%;
  max-width: 280px;
  margin: 0 auto;
  padding: 16px;
  background: var(--beige-light);
  border-radius: var(--border-radius);

  svg, canvas {
    width: 100%;
    height: auto;
  }
`;

export const ShortId = styled.p`
  margin: 8px 0 0;
  font-weight: 500;
`;
