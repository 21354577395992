import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useAtomValue, useSetAtom } from 'jotai';
import T, { useTranslation } from '@wojtekmaj/react-t';
import AsyncButton from '@wojtekmaj/react-async-button';

import { toast } from '@rewardopl/react-toast';

import Button from '@rewardopl/react-ui/button';

import { post } from '@rewardopl/utils/network';

import {
  ContentWrapper,
  ImageWrapper,
  Image,
  ImagePlaceholder,
  Name,
  Price,
  Description,
  Hint,
  ButtonWrapper,
} from './index.styles';

import HeaderWithButton from '../../../../header_with_button';
import PageWrapper from '../../../../../../page_wrapper';

import { formatNumber } from '../../../../../../../utils/helpers';

import {
  transactionsQuery,
  vendorCardRewardQuery,
  vendorCardSubscriptionState,
} from '../../../../../../../store';

import { VENDOR_CARD_ID } from '../../../../../../../constants';

import lockIcon from '../../../../../../../static/lock.svg';
import rewardIcon from '../../../../../../../static/reward.svg';

import type { Transaction } from '@rewardopl/types';

const cardId = VENDOR_CARD_ID;

const pendingConfig = {
  disabled: true,
};

export default function RewardPage() {
  const navigate = useNavigate();
  const { reward_id: rewardId } = useParams();

  if (!rewardId) {
    throw new Error('rewardId is undefined');
  }

  const cardSubscription = useAtomValue(vendorCardSubscriptionState);

  const { _id: cardSubscriptionId, balance } = cardSubscription;

  const setTransactions = useSetAtom(transactionsQuery(cardSubscriptionId));
  const rewardErrorString = useTranslation('Failed to buy the reward');

  const reward = useAtomValue(vendorCardRewardQuery(rewardId));

  if (!reward) {
    return <Navigate to=".." />;
  }

  const { description, name, picture, price } = reward;

  async function onClick() {
    try {
      const response = (await post(`/api/cards/${cardId}/rewards/${rewardId}/buy`)) as Transaction;

      setTransactions((prevTransactions) => [response, ...prevTransactions]);

      setTimeout(() => {
        navigate(`../../reward_received/${response._id}`);
      }, 0);
    } catch {
      toast.error(rewardErrorString);
    }
  }

  const isAvailable = balance >= price;

  return (
    <>
      <HeaderWithButton>Reward</HeaderWithButton>
      <PageWrapper>
        <ContentWrapper>
          <ImageWrapper>
            {picture ? (
              <Image alt={name} src={`/api/uploads/${picture}?w=${320 * devicePixelRatio}`} />
            ) : (
              <ImagePlaceholder alt={name} src={rewardIcon} />
            )}
          </ImageWrapper>
          <Name>{name}</Name>
          <Price>
            {isAvailable ? null : <img alt="" src={lockIcon} />}
            {formatNumber(price)} <T>{`point${price === 1 ? '' : 's'}`}</T>
          </Price>
          {description ? <Description>{description}</Description> : null}
          <Hint>
            {isAvailable ? (
              <T price={price}>
                {
                  'After clicking “Activate” you’ll receive reward, and {price} points will be taken from your account.'
                }
              </T>
            ) : (
              <T>You don’t have enough points to get this reward.</T>
            )}
          </Hint>
          <ButtonWrapper>
            <AsyncButton
              as={Button}
              className="primary"
              disabled={!isAvailable}
              onClick={onClick}
              pendingConfig={pendingConfig}
              type="button"
            >
              <T>Get</T>
            </AsyncButton>
          </ButtonWrapper>
        </ContentWrapper>
      </PageWrapper>
    </>
  );
}
