import { css, createGlobalStyle } from 'styled-components';

import { Button } from '@rewardopl/react-ui/button/index.styles';
import { Link } from '@rewardopl/react-ui/link_button/index.styles';

import colors from '@rewardopl/styles/colors';
import colorsRgb from '@rewardopl/styles/colors_rgb';
import gradients from '@rewardopl/styles/gradients';
import shadows from '@rewardopl/styles/shadows';
import variables from '@rewardopl/styles/variables';

export const linkStyle = css`
  color: var(--link);
  font: inherit;
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &.primary {
    font: inherit;
    font-weight: 500;

    &:hover,
    &:focus {
      color: var(--primary-dark);
      text-decoration: none;
    }
  }
`;

function addUnit(value: string | number): string {
  if (typeof value === 'number') {
    return `${value}px`;
  }

  return value;
}

const vars = css`
  ${Object.entries({
    ...colors,
    ...gradients,
    ...shadows,
  }).map(([name, value]) => `--${name}: ${value};`)}
`;

const unitVars = css`
  ${Object.entries(variables).map(([name, value]) => `--${name}: ${addUnit(value)};`)}
`;

const rgbVars = css`
  ${Object.entries(colorsRgb).map(([name, value]) => `--${name}-rgb: ${value};`)}
`;

export const GlobalStyle = createGlobalStyle`${css`
  :root {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    text-size-adjust: none;

    ${vars}
    ${unitVars}
    ${rgbVars}
  }

  body {
    min-height: 100vh;
    min-height: 100dvh;
    line-height: 1.333;
    margin: 0;
    overflow: hidden;
    background: white;
    color: var(--text);
    font-family: 'Roboto Flex', sans-serif;
    font-weight: 400;
    font-size: 14px;
  }

  * {
    -webkit-user-select: none;
    user-select: none;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    font-weight: 700;
  }

  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 20px;
  }

  a {
    ${linkStyle}
  }

  ul,
  ol {
    margin: 0;
    padding: 0;
    list-style-position: inside;
  }

  button {
    margin: 0;
    cursor: pointer;
  }

  ${Link}, ${Button} {
    font-weight: 500;
  }

  hr {
    width: 100%;
    border: 0;
    border-top: 1px solid gray;
  }

  label {
    display: block;
  }

  table {
    border-collapse: separate;
    border-spacing: 24px 10px;
    margin: -10px -24px;

    th {
      margin-bottom: 4px;
      text-align: left;
      font-weight: 600;
      vertical-align: top;

      @media screen and (min-width: 769px) {
        margin-bottom: 0;
      }
    }

    th,
    td {
      display: block;

      @media screen and (min-width: 769px) {
        display: table-cell;
      }
    }

    tr:not(:first-child) {
      th {
        margin-top: 10px;

        @media screen and (min-width: 769px) {
          margin-top: 0;
        }
      }
    }
  }
`}`;
