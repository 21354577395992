import { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { ToastContainer } from '@rewardopl/react-toast';

import colors from '@rewardopl/styles/colors';

import { GlobalStyle } from './root.styles';

import AuthGate from './auth_gate';
import SplashScreen from './splash_screen';
import ErrorBoundary from './error_boundary';
import OnlineMonitor from './online_monitor';
import PushHandler from './push_handler';

import AuthCallback from './pages/auth/callback';
import LoginPage from './pages/login';
import PasswordResetPage from './pages/password_reset';
import PasswordResetConfirmationPage from './pages/password_reset_confirmation';
import RegisterPage from './pages/register';
import RegisterWithDetailsPage from './pages/register_with_details';
import UnlockPage from './pages/unlock';
import ErrorPage from './pages/error';
import OfflinePage from './pages/offline';
import OnboardingPage from './pages/onboarding';
import IndexPage from './pages/index/index';

import { isLoggedOut, isLoggedIn } from '../helpers/authorization';

export default function Root() {
  return (
    <>
      <GlobalStyle />
      <Helmet>
        <meta name="theme-color" content={colors.background} />
      </Helmet>
      <ToastContainer position="top-right" />
      <Suspense fallback={null}>
        <PushHandler />
      </Suspense>
      <ErrorBoundary fallback={<ErrorPage />}>
        <OnlineMonitor fallback={<OfflinePage />}>
          <Suspense fallback={<SplashScreen />}>
            <Routes>
              <Route
                path="auth/:provider/callback"
                element={
                  <AuthGate anyOf={isLoggedOut}>
                    <AuthCallback />
                  </AuthGate>
                }
              />
              <Route
                path="login/*"
                element={
                  <AuthGate anyOf={isLoggedOut}>
                    <LoginPage />
                  </AuthGate>
                }
              />
              <Route
                path="password_reset"
                element={
                  <AuthGate anyOf={isLoggedOut}>
                    <PasswordResetPage />
                  </AuthGate>
                }
              />
              <Route
                path="password_reset_confirmation"
                element={
                  <AuthGate anyOf={isLoggedOut}>
                    <PasswordResetConfirmationPage />
                  </AuthGate>
                }
              />
              <Route
                path="register"
                element={
                  <AuthGate anyOf={isLoggedOut}>
                    <RegisterPage />
                  </AuthGate>
                }
              />
              <Route
                path="register_with_details/*"
                element={
                  <AuthGate anyOf={isLoggedOut}>
                    <RegisterWithDetailsPage />
                  </AuthGate>
                }
              />
              <Route
                path="unlock"
                element={
                  <AuthGate anyOf={isLoggedOut}>
                    <UnlockPage />
                  </AuthGate>
                }
              />
              <Route
                path="onboarding"
                element={
                  <AuthGate anyOf={isLoggedOut}>
                    <OnboardingPage />
                  </AuthGate>
                }
              />
              <Route
                path="*"
                element={
                  <AuthGate anyOf={isLoggedIn}>
                    <IndexPage />
                  </AuthGate>
                }
              />
            </Routes>
          </Suspense>
        </OnlineMonitor>
      </ErrorBoundary>
    </>
  );
}
