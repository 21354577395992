import styled from 'styled-components';

import Section from '../../section.styles';

export const Text = styled.p`
  margin: 0;
`;

export const CodeWrapper = styled(Section)`
  margin: 16px 12px;
`;

export const ContentWrapper = styled.div`
  flex-grow: 1;
  max-width: 140px;
  margin-left: var(--gap);
`;
