import T from '@wojtekmaj/react-t';

import { ClockIcon, GlobeIcon, HomeIcon, PhoneIcon } from 'lucide-react';

import { Items, Item, Label, Value } from './place_info.styles';

import {
  formatAddress,
  formatOpeningHours,
  formatPhone,
  formatWebsite,
} from '../../../../../../../utils/helpers';

import type { PlaceWithDynamicPropertiesForUser } from '@rewardopl/types';

type PlaceInfoProps = {
  place: PlaceWithDynamicPropertiesForUser;
};

export default function PlaceInfo({ place }: PlaceInfoProps) {
  const { address, opening_hours: openingHours, phone, website } = place;

  return (
    <Items>
      {address ? (
        <Item key="address">
          <HomeIcon />
          <Label>
            <T>Address</T>
          </Label>
          <Value>{formatAddress(address)}</Value>
        </Item>
      ) : null}
      {website ? (
        <Item key="website">
          <GlobeIcon />
          <Label>
            <T>Website</T>
          </Label>
          <Value>{formatWebsite(website)}</Value>
        </Item>
      ) : null}
      {phone ? (
        <Item key="phone">
          <PhoneIcon />
          <Label>
            <T>Phone</T>
          </Label>
          <Value>{formatPhone(phone)}</Value>
        </Item>
      ) : null}
      {openingHours ? (
        <Item key="openingHours">
          <ClockIcon />
          <Label>
            <T>Opening hours</T>
          </Label>
          <Value as="div">{formatOpeningHours(openingHours)}</Value>
        </Item>
      ) : null}
    </Items>
  );
}
