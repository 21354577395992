import styled from 'styled-components';

import Frame from '../frame.styles';

export const Wrapper = styled.div``;

export const List = styled.div`
  display: flex;
  margin: -24px calc((var(--gap) / 2) * -1);
  padding-top: 24px;
  padding-bottom: 24px;
  overflow-x: scroll;
  scrollbar-color: var(--text) transparent;
  scrollbar-width: thin;
  -webkit-overflow-scrolling: touch;
  gap: 8px;

  @media screen and (min-width: 769px) {
    margin: -24px calc(var(--gap) * -1);
  }
`;

export const ListItem = styled.div`
  &:first-child {
    padding-left: calc(var(--gap) / 2);
  }

  &:last-child {
    padding-right: calc(var(--gap) / 2);
  }

  @media screen and (min-width: 769px) {
    &:first-child {
      padding-left: var(--gap);
    }

    &:last-child {
      padding-right: var(--gap);
    }
  }
`;

export const Tile = styled(Frame)`
  flex-grow: 1;
  width: 160px;
  height: 100%;
  padding: 16px;
  overflow: hidden;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;
