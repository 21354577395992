import { useAtomValue } from 'jotai';

import { vendorBusinessState } from '../store';

import type { Flags } from '@rewardopl/types';

export default function useVendorBusinessFlag(flag: keyof Flags): boolean {
  const business = useAtomValue(vendorBusinessState);
  const { flags: vendorBusinessFlags } = business;

  return vendorBusinessFlags?.[flag] ?? false;
}
