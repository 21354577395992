import Item from './item';

import type { CouponWithDynamicProperties } from '@rewardopl/types';

type ItemsProps = {
  items: CouponWithDynamicProperties[];
};

export default function Items({ items }: ItemsProps) {
  return items.map((coupon) => <Item key={coupon._id} couponId={coupon._id} {...coupon} />);
}
