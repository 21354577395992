import styled from 'styled-components';

import { Image as VendorLogoImage } from '../../common/vendor_logo.styles';

export const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  background: var(--background);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  position: fixed;
  inset: 0;

  @media (screen-spanning: single-fold-vertical) {
    width: env(fold-left);
  }

  @media (horizontal-viewport-segments: 2) {
    width: env(viewport-segment-right 0 0);
  }
`;

export const ContentWrapper = styled.div`
  max-width: 400px;
  margin: 0 auto;
  padding: var(--gap);
  text-align: center;

  ${VendorLogoImage} {
    margin: 2em 0 0;
  }

  button {
    display: block;
    margin: 1em auto;
  }
`;

export const Image = styled.img`
  margin: 1em 0;
`;

export const Title = styled.h1`
  font-size: 24px;
`;

export const Sub = styled.p`
  margin-bottom: 2em;
`;
