import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Wrapper = styled.nav`
  width: 100%;
  position: relative;
  margin: 0 auto;

  @media screen and (min-width: 769px) {
    margin: 0;
  }
`;

export const List = styled.ul<{ itemCount: number }>`
  --menu-gap: 10px;
  display: flex;
  list-style: none;
  justify-content: ${(props) => (props.itemCount >= 5 ? 'space-between' : 'space-around')};
  padding: 0 10px 10px;
  gap: var(--menu-gap);

  @media screen and (min-width: 769px) {
    flex-direction: column;
    padding: 0 5px;
  }
`;

export const ListItem = styled.li`
  flex-basis: min-content;
  min-width: 58px;
  max-width: 74px;
  height: 100%;
  text-align: center;

  &:empty {
    display: none;
  }

  @media screen and (min-width: 769px) {
    max-width: unset;
    width: auto;
    text-align: left;
  }
`;

export const ListItemUnderlay = styled.div`
  display: none;
  pointer-events: none;

  @media screen and (min-width: 769px) {
    display: block;
    position: absolute;
    z-index: -1;
    inset: calc((var(--menu-gap) / 2)) 0;
    border-radius: 8px;
    background-color: rgba(var(--green-light-rgb), 0.2);
    opacity: 0;
    scale: 0.85;
    transition:
      background-color ease-in-out 200ms,
      opacity ease-out 100ms,
      scale ease-out 100ms;
  }
`;

export const ListItemLink = styled(NavLink)<{ active?: boolean; primary?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 8px 0;
  color: var(--green-light);
  text-decoration: none;
  font-size: 10px;
  font-weight: 600;
  gap: 5px;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  ${(props) =>
    props.active &&
    css`
      color: var(--active-menu-item);
    `}

  ${(props) =>
    props.primary &&
    css`
      color: var(--green-light);

      ${
        props.active &&
        css`
          color: var(--active-menu-item);
        `
      }
    `}

  @media screen and (min-width: 769px) {
    min-height: calc(40px + var(--menu-gap));
    flex-direction: row;
    margin: calc((var(--menu-gap) / 2) * -1) 0;
    padding: calc(8px + (var(--menu-gap) / 2)) 12px;
    padding-left: calc(var(--gap) - 10px);
    border-radius: 8px;
    font-size: inherit;
    transition: background-color ease-in-out 200ms;
    gap: 8px;

    &:hover,
    &:focus {
      ${ListItemUnderlay} {
        opacity: 1;
        scale: 1;
      }
    }

    ${(props) =>
      props.active &&
      css`
        color: var(--green-dark);

        ${ListItemUnderlay} {
          background-color: var(--green-light);
          opacity: 1;
          scale: 1;
        }
      `}

    ${(props) =>
      props.primary &&
      css`
        color: var(--green-light);
        ${
          props.active &&
          css`
            color: var(--green-dark);

            ${ListItemUnderlay} {
              background-color: var(--green-light);
            }
          `
        }
      `}
  }
`;

export const TabIndicator = styled.div`
  position: absolute;
  bottom: 5px;
  width: 7px;
  height: 7px;
  margin: 0 !important;
  background: var(--green-light);
  border-radius: var(--border-radius-round);
  transform: translateX(-50%);

  @media screen and (min-width: 769px) {
    display: none;
  }
`;

export const Icon = styled.span<{ active?: boolean; primary?: boolean }>`
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
  line-height: 0;

  img,
  svg {
    width: 100%;
    height: 100%;
  }

  ${(props) =>
    props.primary &&
    css`
      width: 64px;
      height: 64px;
      margin-top: -40px;
      background: var(--green-light);
      border-radius: var(--border-radius-round);
      color: var(--text);

      img,
      svg {
        width: 24px;
        height: 24px;
      }

      ${
        props.active &&
        css`
          background: white; /* TODO */
        `
      }
    `}

  @media screen and (min-width: 769px) {
    ${(props) =>
      props.primary &&
      css`
        width: 24px;
        height: 24px;
        margin-top: 0;
        background: transparent;
        color: var(--green-light);

        ${
          props.active &&
          css`
            color: var(--green-dark);
          `
        }
      `}
  }
`;

export const Label = styled.span`
  font-size: 10px;
  letter-spacing: 0.4px;

  @media screen and (min-width: 769px) {
    font-size: 14px;
  }
`;
