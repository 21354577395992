import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAtomValue } from 'jotai';
import T from '@wojtekmaj/react-t';

import Dialog from '@rewardopl/react-ui/dialog';

import colors from '@rewardopl/styles/colors';

import { Text, CodeWrapper } from './your_code_dialog.styles';

import Code from '../../common/code';

import { currentUserState } from '../../../store';

export default function YourCodeDialog() {
  const location = useLocation();
  const navigate = useNavigate();
  const currentUser = useAtomValue(currentUserState);

  const closeDialog = useCallback(() => {
    navigate(
      {
        ...location,
        hash: '',
      },
      { state: location.state },
    );
  }, [location, navigate]);

  return (
    <Dialog isOpen={location.hash === '#your-code'} onDismiss={closeDialog} title="My card">
      <Text>
        <T>Show the code every time you visit Salad Story to earn points for your purchases.</T>
      </Text>
      <CodeWrapper>
        <Code color={colors.text} id={currentUser._id} />
      </CodeWrapper>
    </Dialog>
  );
}
