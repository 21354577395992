import styled from 'styled-components';

import { Tile } from '../../../../../../common/scrollable_tiles.styles';

export const Offer = styled(Tile)`
  width: 100%;
  min-height: 200px;
  padding: 0;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  @media screen and (min-width: 768px) {
    width: 360px;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
