import { useRef, useState } from 'react';
import { useAtomValue } from 'jotai';
import { Helmet } from 'react-helmet-async';
import T from '@wojtekmaj/react-t';

import Button from '@rewardopl/react-ui/button';
import LinkButton from '@rewardopl/react-ui/link_button';

import colors from '@rewardopl/styles/colors';

import { PageWrapper, ContentWrapper, Title, ButtonWrapper } from './index.styles';

import ScrollableSlides from './scrollable_slides';
import Slide from './slide';

import { scrollToChild } from '../../../utils/carousel';

import { configLoginLoadable } from '../../../store';

import onboarding1Png from '../../../static/onboarding/onboarding-1.png';
import onboarding1Png2 from '../../../static/onboarding/onboarding-1@2x.png';
import onboarding1Webp from '../../../static/onboarding/onboarding-1.webp';
import onboarding1Webp2 from '../../../static/onboarding/onboarding-1@2x.webp';
import onboarding1Avif from '../../../static/onboarding/onboarding-1.avif';
import onboarding1Avif2 from '../../../static/onboarding/onboarding-1@2x.avif';

import onboarding2Png from '../../../static/onboarding/onboarding-2.png';
import onboarding2Png2 from '../../../static/onboarding/onboarding-2@2x.png';
import onboarding2Webp from '../../../static/onboarding/onboarding-2.webp';
import onboarding2Webp2 from '../../../static/onboarding/onboarding-2@2x.webp';
import onboarding2Avif from '../../../static/onboarding/onboarding-2.avif';
import onboarding2Avif2 from '../../../static/onboarding/onboarding-2@2x.avif';

import onboarding3Png from '../../../static/onboarding/onboarding-3.png';
import onboarding3Png2 from '../../../static/onboarding/onboarding-3@2x.png';
import onboarding3Webp from '../../../static/onboarding/onboarding-3.webp';
import onboarding3Webp2 from '../../../static/onboarding/onboarding-3@2x.webp';
import onboarding3Avif from '../../../static/onboarding/onboarding-3.avif';
import onboarding3Avif2 from '../../../static/onboarding/onboarding-3@2x.avif';

const slides = [
  {
    id: 1,
    fetchPriority: 'high',
    image: onboarding1Png,
    image2: onboarding1Png2,
    imageWebp: onboarding1Webp,
    imageWebp2: onboarding1Webp2,
    imageAvif: onboarding1Avif,
    imageAvif2: onboarding1Avif2,
    text: 'Participate in promotions',
  },
  {
    id: 2,
    image: onboarding2Png,
    image2: onboarding2Png2,
    imageWebp: onboarding2Webp,
    imageWebp2: onboarding2Webp2,
    imageAvif: onboarding2Avif,
    imageAvif2: onboarding2Avif2,
    text: 'Collect points',
  },
  {
    id: 3,
    image: onboarding3Png,
    image2: onboarding3Png2,
    imageWebp: onboarding3Webp,
    imageWebp2: onboarding3Webp2,
    imageAvif: onboarding3Avif,
    imageAvif2: onboarding3Avif2,
    text: 'Exchange points for rewards',
  },
] as const;

export default function OnboardingPage() {
  const scrollContainer = useRef<HTMLDivElement>(null);
  const [currentChildIndex, onChildChange] = useState(0);
  useAtomValue(configLoginLoadable);

  function scrollToNext() {
    if (!scrollContainer.current) {
      return;
    }

    scrollToChild(currentChildIndex + 1, scrollContainer.current);
  }

  return (
    <>
      <Helmet>
        <meta name="theme-color" content={colors.background} />
      </Helmet>
      <PageWrapper>
        <ContentWrapper>
          <Title>
            <T>Welcome to Salad Story!</T>
          </Title>
          <ScrollableSlides scrollContainer={scrollContainer} onChildChange={onChildChange}>
            {slides.map((slide) => (
              <Slide key={slide.id} {...slide} />
            ))}
          </ScrollableSlides>
          <ButtonWrapper>
            {currentChildIndex === slides.length - 1 ? (
              <LinkButton to="/login" className="primary" key="begin">
                <T>Let’s begin</T>
              </LinkButton>
            ) : (
              <>
                <LinkButton to="/login" key="skip">
                  <T>Skip</T>
                </LinkButton>
                <Button className="primary" onClick={scrollToNext} type="button">
                  <T>Next</T>
                </Button>
              </>
            )}
          </ButtonWrapper>
        </ContentWrapper>
      </PageWrapper>
    </>
  );
}
