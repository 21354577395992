import { useEffect } from 'react';
import { useSetAtom } from 'jotai';

import { get } from '@rewardopl/utils/network';

import { vendorProductsState } from '../store';

import { VENDOR_BUSINESS_ID } from '../constants';

import type { ProductWithDynamicPropertiesForUser } from '@rewardopl/types';

export default function useVendorProductsRefresh(): void {
  const setVendorProducts = useSetAtom(vendorProductsState);

  useEffect(() => {
    get(`/api/businesses/${VENDOR_BUSINESS_ID}/products`)
      .then((nextVendorProducts) => {
        setVendorProducts((prevVendorProducts) =>
          JSON.stringify(nextVendorProducts) !== JSON.stringify(prevVendorProducts)
            ? (nextVendorProducts as ProductWithDynamicPropertiesForUser[])
            : prevVendorProducts,
        );
      })
      .catch(() => {
        // Do nothing
      });
  }, [setVendorProducts]);
}
