import { useState } from 'react';
import T from '@wojtekmaj/react-t';
import AsyncButton from '@wojtekmaj/react-async-button';

import Button from '@rewardopl/react-ui/button';
import Dialog from '@rewardopl/react-ui/dialog';
import TextareaField from '@rewardopl/react-ui/textarea_field';

import { Wrapper, Question, FeedbackWrapper } from './dialog.styles';

import RatingButton from '../../../common/rating_button';

import type { Transaction } from '@rewardopl/types';

const pendingConfig = {
  disabled: true,
};

type TransactionsMonitorDialogProps = {
  onDismiss: () => void;
  transaction: Transaction;
};

export default function TransactionsMonitorDialog({
  onDismiss,
  transaction,
}: TransactionsMonitorDialogProps) {
  const [serviceRating, setServiceRating] = useState<number>();
  const [productRating, setProductRating] = useState<number>();

  const rating =
    serviceRating !== undefined && productRating !== undefined
      ? (serviceRating + productRating) / 2
      : undefined;

  const action = '/api/feedback';

  async function onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();

    const submitButton = event.currentTarget;
    const form = submitButton.form;

    if (!form || !form.reportValidity()) {
      return;
    }

    await fetch(action, {
      method: 'POST',
      body: new FormData(form),
    });

    onDismiss();
  }

  return (
    <Dialog isOpen onDismiss={onDismiss} title="Rate your visit">
      <Wrapper>
        <Question>
          <T>How would you rate the service?</T>
        </Question>
        <RatingButton rating={serviceRating} onChange={setServiceRating} />
        <Question>
          <T>How would you rate the taste and quality of the products you ordered?</T>
        </Question>
        <RatingButton rating={productRating} onChange={setProductRating} />
        <form action={action} method="POST">
          <input
            type="hidden"
            name="card_subscription_id"
            value={transaction.card_subscription_id}
          />
          <input type="hidden" name="transaction_id" value={transaction._id} />
          <input type="hidden" name="service_rating" value={serviceRating} />
          <input type="hidden" name="product_rating" value={productRating} />
          <input type="hidden" name="rating" value={rating} />
          <FeedbackWrapper>
            <TextareaField
              label={
                <Question>
                  <T>What can we do better?</T>
                </Question>
              }
              name="comment"
              required={false}
              rows={5}
            />
          </FeedbackWrapper>
          <AsyncButton
            as={Button}
            className="primary"
            disabled={rating === undefined}
            onClick={onClick}
            pendingConfig={pendingConfig}
            type="submit"
          >
            <T>Submit</T>
          </AsyncButton>
        </form>
      </Wrapper>
    </Dialog>
  );
}
