import styled from 'styled-components';

import { Tile } from '../../../../../../common/scrollable_tiles.styles';

const shouldForwardProp = (prop: string) => !['isAvailable'].includes(prop);

export const Reward = styled(Tile).withConfig({ shouldForwardProp })`
  width: 134px;
  min-height: 170px;
  padding: 4px 8px 8px 8px;
  flex-direction: column;
  align-items: center;
`;

export const ImageWrapper = styled.div`
  width: 118px;
  height: 118px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const ImagePlaceholder = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const ContentWrapper = styled.div`
  padding-top: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  text-align: center;
`;

export const Name = styled.p`
  margin: 0;
  font-weight: 700;
`;

export const Price = styled.p`
  height: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin: 0;
  padding: 2.5px 8px 1.5px;
  border: 1px solid var(--green-dark);
  border-radius: 8px;
  font-weight: 700;
  line-height: 12px;
  overflow: hidden;
`;
