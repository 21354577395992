import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--gap);
`;

export const LogoWrapper = styled.div`
  line-height: 0;
`;

export const HeaderContentWrapper = styled.div`
  margin-bottom: -10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Subject = styled.h3`
  margin: 0;
  font-size: 24px;
`;

export const MessageDate = styled.p`
  margin: 0;
  font-size: 12px;
`;

export const Body = styled.p`
  margin: 0;
  line-height: 1.5;
  white-space: pre-wrap;
`;

export const Picture = styled.img`
  max-width: 100%;
  height: auto;
`;
