import { useAtomValue } from 'jotai';

import { ItemWrapper } from './index.styles';

import Items from '../../main/coupons/items';

import PageWrapper from '../../../../../../page_wrapper';
import Heading from '../../../../../../heading';

import { vendorCardCouponsState } from '../../../../../../../store';

export default function CouponsMain() {
  const cardCoupons = useAtomValue(vendorCardCouponsState);

  const cardCouponsWithoutHiddenDeactivated = cardCoupons.filter(
    (coupon) => (!coupon.is_hidden && coupon.can_be_used) || coupon.is_activated,
  );

  return (
    <PageWrapper>
      <Heading align="center">Coupons and offers</Heading>
      <ItemWrapper>
        <Items items={cardCouponsWithoutHiddenDeactivated} />
      </ItemWrapper>
    </PageWrapper>
  );
}
