import styled from 'styled-components';

export const Image = styled.img`
  width: 100%;
  height: auto;
  display: block;
`;

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  margin-top: -24px;
  padding: var(--gap) 12px;
  background: var(--background);
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  z-index: 1;

  @media screen and (min-width: 769px) {
    padding: var(--gap);
  }
`;

export const Description = styled.p`
  margin: 0;
  white-space: pre-wrap;
`;
