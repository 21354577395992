import styled from 'styled-components';

export const PageWrapper = styled.div`
  min-height: 100vh;
  min-height: 100dvh;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-areas: 'header' 'main' 'footer';
  background: var(--background);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media (screen-spanning: single-fold-vertical) {
    width: env(fold-left);
  }

  @media (horizontal-viewport-segments: 2) {
    width: env(viewport-segment-right 0 0);
  }
`;

export const HeaderAndFormWrapper = styled.div`
  margin: auto;
`;

export const FormWrapper = styled.div`
  width: 400px;
  max-width: 100vw;
  padding: var(--gap);

  form {
    margin: var(--gap) 0;
  }
`;

export const Main = styled.main`
  text-align: center;

  @media screen and (min-width: 769px) {
    text-align: left;
  }
`;
