import { Navigate } from 'react-router-dom';

import { HeaderAndFormWrapper, PageWrapper, Main, FormWrapper } from '../login/index.styles';

import Heading from '../../heading';
import Header from '../../header';
import UnlockForm from './unlock_form';
import Footer from '../../footer';

import useLocationParams from '../../../hooks/useLocationParams';

import type { User } from '@rewardopl/types';

export default function UnlockPage() {
  const [params] = useLocationParams() as [{ id?: User['_id']; token?: string }, () => void];

  const { id, token } = params;

  const hasParams = id && token;

  if (!hasParams) {
    return <Navigate to="/login" />;
  }

  return (
    <PageWrapper>
      <HeaderAndFormWrapper>
        <Header />
        <FormWrapper>
          <Main>
            <Heading align="center">Unlock account</Heading>
            <UnlockForm id={id} token={token} />
          </Main>
        </FormWrapper>
      </HeaderAndFormWrapper>
      <Footer />
    </PageWrapper>
  );
}
