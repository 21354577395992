import { useSetAtom } from 'jotai';
import T from '@wojtekmaj/react-t';
import AsyncButton from '@wojtekmaj/react-async-button';

import Button from '@rewardopl/react-ui/button';

import { get, post } from '@rewardopl/utils/network';

import { vendorCardCouponActivationsQuery } from '../../../../../../../store';

import { VENDOR_CARD_ID } from '../../../../../../../constants';

import type { CouponActivation, CouponWithDynamicProperties } from '@rewardopl/types';

const cardId = VENDOR_CARD_ID;

const pendingConfig = {
  disabled: true,
};

type ActivateButtonProps = {
  coupon: CouponWithDynamicProperties;
  setCoupon: (coupon: CouponWithDynamicProperties) => void;
};

export default function ActivateButton({ coupon, setCoupon }: ActivateButtonProps) {
  const { _id: couponId, is_activated: isActivated, is_hidden: isHidden } = coupon;

  const setCouponActivations = useSetAtom(vendorCardCouponActivationsQuery(couponId));

  if (isActivated && isHidden) {
    return null;
  }

  const action = `/api/cards/${cardId}/coupons/${couponId}/${
    isActivated ? 'deactivate' : 'activate'
  }`;

  async function onClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();

    await post(action);

    await get(`/api/cards/${cardId}/coupons/${couponId}/coupon_activations`).then(
      (nextCouponActivations) => {
        setCoupon({ ...coupon, is_activated: !isActivated });

        setCouponActivations(nextCouponActivations as CouponActivation[]);
      },
    );
  }

  return (
    <AsyncButton
      as={Button}
      className="primary"
      onClick={onClick}
      pendingConfig={pendingConfig}
      type="button"
    >
      <T>{isActivated ? 'Deactivate' : 'Activate'}</T>
    </AsyncButton>
  );
}
