import { Suspense } from 'react';

import { Wrapper } from './index.styles';

import Nav from './nav';
import Content from './content';
import YourCodeDialog from './your_code_dialog';
import WebsocketHandler from './websocket_handler';
import TransactionsMonitor from './transactions_monitor';
import UrgentMessagesMonitor from './urgent_messages_monitor';

export default function IndexPage() {
  return (
    <Wrapper>
      <Nav />
      <Content />
      <YourCodeDialog />
      <WebsocketHandler />
      <Suspense fallback={null}>
        <TransactionsMonitor />
      </Suspense>
      <Suspense fallback={null}>
        <UrgentMessagesMonitor />
      </Suspense>
    </Wrapper>
  );
}
