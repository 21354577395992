import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import T, { useTranslation } from '@wojtekmaj/react-t';
import AsyncButton from '@wojtekmaj/react-async-button';

import { toast } from '@rewardopl/react-toast';

import Button from '@rewardopl/react-ui/button';
import CheckboxField from '@rewardopl/react-ui/checkbox_field';
import InputField from '@rewardopl/react-ui/input_field';

import { post } from '@rewardopl/utils/network';

import { Form, ButtonWrapper, Indent } from '../../login/email/form.styles';

import MarketingDisclaimers from '../../../common/marketing_disclaimers';
import TermsAndConditionsLink from '../../../terms_and_conditions_link';
import PrivacyPolicyLink from '../../../privacy_policy_link';

import { isPasswordValid } from '../../../../utils/password';

import useLocationParam from '../../../../hooks/useLocationParam';

const pendingConfig = {
  disabled: true,
};

export default function RegisterWithDetailsStepRegisterForm() {
  const location = useLocation();
  const [referralId] = useLocationParam('referral_id');
  const navigate = useNavigate();
  const registerErrorString = useTranslation('Failed to register');
  const userAlreadyExistsString = useTranslation('User already exists');
  const passwordInvalidString = useTranslation(
    'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.',
  );

  const { email: stateEmail = '', returnPath = '/' } = (location.state || {}) as {
    email?: string;
    returnPath?: string;
  };

  const [email, setEmail] = useState(stateEmail);
  const [password, setPassword] = useState('');
  const [marketingEmailAccepted, setMarketingEmailAccepted] = useState(false);
  const [marketingSmsAccepted, setMarketingSmsAccepted] = useState(false);
  const marketingAccepted = marketingEmailAccepted || marketingSmsAccepted;

  const action = '/api/users/check';

  async function onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();

    const submitButton = event.currentTarget;
    const form = submitButton.form;

    if (!form || !form.reportValidity()) {
      return;
    }

    const formData = new FormData(form);

    try {
      const { exists } = (await post(action, {
        body: formData,
      })) as { exists: boolean };

      if (exists) {
        toast.error(userAlreadyExistsString);
        navigate('/login', { state: { email: formData.get('email'), returnPath } });
        return;
      }

      navigate('../details', {
        state: {
          email: formData.get('email'),
          password: formData.get('password'),
          termsAccepted: formData.get('terms_accepted'),
          marketingAccepted: formData.get('marketing_accepted'),
          marketingEmailAccepted: formData.get('marketing_email_accepted'),
          marketingSmsAccepted: formData.get('marketing_sms_accepted'),
          returnPath,
        },
      });
    } catch {
      toast.error(registerErrorString);
    }
  }

  return (
    <Form action={action} method="POST">
      <input name="referral_id" value={referralId} type="hidden" />
      <InputField
        autoComplete="username"
        label="Email"
        maxLength={320}
        name="email"
        onChange={setEmail}
        placeholder="email@example.com"
        type="email"
        value={email}
      />
      <InputField
        autoComplete="new-password"
        label="Password"
        hint="Min. 8 characters, uppercase letter, lowercase letter, number, special character"
        minLength={8}
        name="password"
        onChange={(nextPassword, event) => {
          setPassword(nextPassword);

          event.target.setCustomValidity(
            isPasswordValid(nextPassword, email) ? '' : passwordInvalidString,
          );
        }}
        placeholder="••••••••"
        type="password"
        value={password}
      />
      <CheckboxField
        name="terms_accepted"
        label={
          <>
            <T>I have read and agree to the</T> <TermsAndConditionsLink /> <T>and</T>{' '}
            <PrivacyPolicyLink />.
          </>
        }
        required
      />
      <CheckboxField
        checked={marketingAccepted}
        name="marketing_accepted"
        label={
          <T>
            I consent to Salad Story S.A. providing information about the latest promotions,
            products and services of its own and about the promotions, products and services of its
            partners via e-mail or push messages.
          </T>
        }
        onChange={() => {
          setMarketingEmailAccepted(!marketingAccepted);
          setMarketingSmsAccepted(!marketingAccepted);
        }}
      />
      <Indent>
        <CheckboxField
          checked={marketingSmsAccepted}
          name="marketing_sms_accepted"
          label={<T>SMS</T>}
          onChange={() => {
            setMarketingSmsAccepted(!marketingSmsAccepted);
          }}
        />
        <CheckboxField
          checked={marketingEmailAccepted}
          name="marketing_email_accepted"
          label={<T>E-mail</T>}
          onChange={() => {
            setMarketingEmailAccepted(!marketingEmailAccepted);
          }}
        />
      </Indent>
      <MarketingDisclaimers />
      <ButtonWrapper>
        <AsyncButton
          as={Button}
          className="primary"
          onClick={onClick}
          pendingConfig={pendingConfig}
          type="submit"
        >
          <T>Continue</T>
        </AsyncButton>
      </ButtonWrapper>
    </Form>
  );
}
