import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '@wojtekmaj/react-t';

import { toast } from '@rewardopl/react-toast';

import { post } from '@rewardopl/utils/network';

import LoadingText from '../../loading_text';

import type { User } from '@rewardopl/types';

type UnlockFormProps = {
  id: User['_id'];
  token: NonNullable<User['unlock_token']>;
};

export default function UnlockForm({ id, token }: UnlockFormProps) {
  const navigate = useNavigate();
  const userNotLockedString = useTranslation('User has already been unlocked');
  const tokenIsInvalidString = useTranslation(
    'Unlock link is invalid. Check if you copied the link correctly',
  );
  const tokenIsExpiredString = useTranslation(
    'Unlock link has expired. Request password reset again',
  );
  const genericErrorString = useTranslation('Failed to unlock user');

  const action = `/api/users/${id}/unlock`;

  useEffect(() => {
    (async () => {
      const formData = new FormData();

      formData.append('_id', id);
      formData.append('token', token);

      try {
        await post(action, {
          body: formData,
        });

        navigate('/login');
      } catch (err) {
        const error = err;

        if (error instanceof Error && error.message === 'User is not locked') {
          toast.success(userNotLockedString);

          navigate('/login');

          return;
        }

        const errorString = (() => {
          if (!(error instanceof Error)) {
            return genericErrorString;
          }

          switch (error.message) {
            case 'token is invalid':
              return tokenIsInvalidString;
            case 'token is expired':
              return tokenIsExpiredString;
            default:
              return genericErrorString;
          }
        })();

        toast.error(errorString);
      }
    })();
  }, [
    action,
    genericErrorString,
    id,
    navigate,
    token,
    tokenIsExpiredString,
    tokenIsInvalidString,
    userNotLockedString,
  ]);

  return <LoadingText />;
}
