import {
  Wrapper,
  ImageWrapper,
  Image,
  ContentWrapper,
  Name,
  RatingWrapper,
} from './content.styles';

import Rating from '../../../../../../../common/rating';
import MiniNutritionFacts from '../../../../../products/product/mini_nutrition_facts';

import type {
  Product,
  ProductProduct,
  ProductWithDynamicPropertiesForUser,
} from '@rewardopl/types';

function getIsProductProduct(product: Product): product is ProductProduct {
  return product.type === 'product';
}

type ContentProps = {
  product: ProductWithDynamicPropertiesForUser;
};

export default function Content({ product }: ContentProps) {
  const isProductProduct = getIsProductProduct(product);

  return (
    <Wrapper>
      {product.picture ? (
        <ImageWrapper>
          <Image
            alt={product.name}
            src={`/api/uploads/${product.picture}?w=${150 * devicePixelRatio}`}
          />
        </ImageWrapper>
      ) : null}
      <ContentWrapper>
        <Name>{product.name}</Name>
        <RatingWrapper>
          <Rating rating={product.rating} />
        </RatingWrapper>
        {isProductProduct ? <MiniNutritionFacts item={product} vertical /> : null}
      </ContentWrapper>
    </Wrapper>
  );
}
