import { toCanvas } from '@bwip-js/browser';

type BarcodeProps = {
  bgColor?: string;
  fgColor?: string;
  value: string;
};

export default function Barcode({ bgColor = '#fff', fgColor = '#000', value }: BarcodeProps) {
  const valueNumbersOnly = value.replace(/\D/g, '');

  return (
    <canvas
      ref={(canvas) => {
        if (!canvas) {
          return;
        }

        toCanvas(canvas, {
          bcid: 'code128',
          text: valueNumbersOnly,
          scale: devicePixelRatio,
          height: 15,
          includetext: false,
          backgroundcolor: bgColor,
          textcolor: fgColor,
        });
      }}
    />
  );
}
