import { toObject } from '@wojtekmaj/color-utils';

import colors from './colors';

const colorsRgb = Object.entries(colors).reduce(
  (obj, [name, value]) => {
    try {
      const { r, g, b } = toObject(value);

      obj[name as keyof typeof colors] = `${r}, ${g}, ${b}`;
    } catch {
      // Do nothing - some colors can't be converted to RGB
    }

    return obj;
  },
  {} as Record<keyof typeof colors, string>,
);

export default colorsRgb;
