import styled from 'styled-components';

export const Line = styled.div`
  height: 1px;
  background-color: var(--secondary-dark);
`;

export const Small = styled.p`
  margin: 16px 0;
  font-size: 10px;
  line-height: 15px;
  color: var(--text-light);
`;
