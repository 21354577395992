import { patch, post } from '@rewardopl/utils/network';

import { APP_ID, NOTIFICATION_CATEGORIES, VAPID_PUBLIC_KEY } from '../constants';

import type { User } from '@rewardopl/types';

const areNotificationsSupported = 'Notification' in window;
const isPushManagerSupported = 'PushManager' in window;

const allNotifications = Object.values(NOTIFICATION_CATEGORIES).reduce<Record<string, boolean>>(
  (acc, notificationCategory) => {
    acc[notificationCategory] = true;
    return acc;
  },
  {},
);

export async function requestPermission(): Promise<'granted'> {
  const notificationPermission = await Notification.requestPermission();

  if (notificationPermission !== 'granted') {
    throw new Error('Notification permission denied');
  }

  return notificationPermission;
}

function getServiceWorkerRegistration(): Promise<ServiceWorkerRegistration | undefined> {
  return navigator.serviceWorker.getRegistration();
}

async function getSubscription(): Promise<PushSubscription | null> {
  const serviceWorkerRegistration = await getServiceWorkerRegistration();

  if (!serviceWorkerRegistration) {
    throw new Error('Service worker registration not found');
  }

  if (!serviceWorkerRegistration.pushManager) {
    throw new Error('Push manager not supported');
  }

  return serviceWorkerRegistration.pushManager.getSubscription();
}

async function getOrCreateSubscription(): Promise<PushSubscription> {
  const pushSubscription = await getSubscription();

  if (pushSubscription) {
    return pushSubscription;
  }

  const serviceWorkerRegistration = await getServiceWorkerRegistration();

  if (!serviceWorkerRegistration) {
    throw new Error('Service worker registration not found');
  }

  if (!serviceWorkerRegistration.pushManager) {
    throw new Error('Push manager not supported');
  }

  const options = {
    userVisibleOnly: true,
    applicationServerKey: VAPID_PUBLIC_KEY,
  };

  return serviceWorkerRegistration.pushManager.subscribe(options);
}

export async function subscribe(currentUser: User): Promise<User> {
  if (!areNotificationsSupported || !isPushManagerSupported) {
    throw new Error('Push notifications are not supported');
  }

  await requestPermission();

  const pushSubscription = await getOrCreateSubscription();

  if (
    currentUser.notification_subscriptions?.find(
      (currentSubscription) =>
        'endpoint' in currentSubscription &&
        currentSubscription.endpoint === pushSubscription.endpoint,
    )
  ) {
    // User already subscribed
    return currentUser;
  }

  const { _id: userId } = currentUser;

  const response = await post(`/api/users/${userId}/notifications/subscribe`, {
    body: {
      _appId: APP_ID,
      ...(JSON.parse(JSON.stringify(pushSubscription)) as typeof pushSubscription),
    },
  });

  return response as User;
}

export async function subscribeToAll(currentUser: User): Promise<User> {
  const action = `/api/users/${currentUser._id}`;

  const response = await patch(action, {
    body: {
      notifications: allNotifications,
    },
  });

  return response as User;
}

export async function unsubscribeFromAll(currentUser: User): Promise<User> {
  if (!currentUser.notification_subscriptions) {
    // User already unsubscribed
    return currentUser;
  }

  const { _id: userId } = currentUser;

  const response = await post(`/api/users/${userId}/notifications/unsubscribe`);

  return response as User;
}

export async function unsubscribe(currentUser: User): Promise<User> {
  if (!currentUser.notification_subscriptions) {
    // User has no subscriptions
    return currentUser;
  }

  const pushSubscription = await getSubscription();

  if (!pushSubscription) {
    return currentUser;
  }

  await pushSubscription.unsubscribe();

  const filteredSubscriptions = currentUser.notification_subscriptions.filter(
    (currentSubscription) =>
      !('endpoint' in currentSubscription) ||
      currentSubscription.endpoint !== pushSubscription.endpoint,
  );

  const { _id: userId } = currentUser;

  const response = await patch(`/api/users/${userId}/notifications`, {
    body: {
      notification_subscriptions: filteredSubscriptions,
    },
  });

  return response as User;
}
