import T from '@wojtekmaj/react-t';

import { Wrapper, Kcal, Properties, Property, PropertyValue } from './mini_nutrition_facts.styles';

import { formatNumber } from '../../../../../utils/helpers';

import type { ProductProduct } from '@rewardopl/types';

type MiniNutritionFactsProps = {
  item: ProductProduct;
  vertical?: boolean;
};

export default function MiniNutritionFacts({ item, vertical }: MiniNutritionFactsProps) {
  const { nutrition_facts: nutritionFacts, serving_size: servingSize = 100 } = item;

  if (!nutritionFacts) {
    return null;
  }

  // Nutrition facts are provided per 100 g, so we need to calculate them for the serving size
  function calculateNutrient(nutrient: string | number) {
    return formatNumber((Number(nutrient) / 100) * servingSize, 1);
  }

  return (
    <Wrapper vertical={vertical}>
      {nutritionFacts.calories ? (
        <Kcal vertical={vertical}>{calculateNutrient(nutritionFacts.calories)} kcal</Kcal>
      ) : null}
      <Properties>
        {nutritionFacts.proteins ? (
          <Property>
            <T>P</T>: <PropertyValue>{calculateNutrient(nutritionFacts.proteins)}</PropertyValue> g
          </Property>
        ) : null}
        {nutritionFacts.carbohydrates ? (
          <Property>
            <T>C</T>:{' '}
            <PropertyValue>{calculateNutrient(nutritionFacts.carbohydrates)}</PropertyValue> g
          </Property>
        ) : null}
        {nutritionFacts.fat ? (
          <Property>
            <T>F</T>: <PropertyValue>{calculateNutrient(nutritionFacts.fat)}</PropertyValue> g
          </Property>
        ) : null}
      </Properties>
    </Wrapper>
  );
}
