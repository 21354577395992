import styled from 'styled-components';

export const Form = styled.form``;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: var(--gap);
  margin: 40px 0;
`;

export const Indent = styled.div`
  margin-left: 42px;
`;
