import styled from 'styled-components';

export const Wrapper = styled.header`
  display: flex;
  justify-content: center;
`;

export const Image = styled.img`
  width: 375px;
  max-width: 100%;
  height: auto;
  aspect-ratio: 375 / 225;
`;
