import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ vertical?: boolean }>`
  margin-top: 16px;
  display: flex;
  align-items: flex-start;
  ${(props) =>
    props.vertical
      ? css`
          flex-direction: column;
          gap: 4px;
          font-size: 12px;
        `
      : css`
          flex-direction: row;
          gap: 8px;
          font-size: 14px;
        `}
`;

export const Kcal = styled.p<{ vertical?: boolean }>`
  height: ${(props) => (props.vertical ? '16px' : '18px')};
  margin: 0;
  padding: 2.5px 8px 1.5px;
  border: 1px solid var(--green-dark);
  border-radius: 8px;
  font-weight: 700;
  line-height: ${(props) => (props.vertical ? '10px' : '12px')};
  overflow: hidden;
`;

export const Properties = styled.div`
  display: flex;
  gap: 4px;
`;

export const Property = styled.p<{ vertical?: boolean }>`
  margin: 0;
  line-height: ${(props) => (props.vertical ? '16px' : '18px')};
`;

export const PropertyValue = styled.span`
  font-weight: 700;
`;
