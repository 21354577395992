import { useTranslation } from '@wojtekmaj/react-t';
import { StarIcon } from 'lucide-react';

import { makeIndexArray } from '@rewardopl/utils/array';

import { Wrapper } from './rating.styles.js';

type LikeCountProps = {
  rating?: number;
};

export default function Rating({ rating = 0 }: LikeCountProps) {
  const oneStarString = useTranslation('1 star');
  const twoStarsString = useTranslation('{count} stars', { count: 2 });
  const threeStarsString = useTranslation('{count} stars', { count: 3 });
  const fourStarsString = useTranslation('{count} stars', { count: 4 });
  const fiveStarsString = useTranslation('{count} stars', { count: 5 });

  const starsStrings = [
    oneStarString,
    twoStarsString,
    threeStarsString,
    fourStarsString,
    fiveStarsString,
  ];

  const ratingStars = Math.ceil(rating / 20);

  return (
    <Wrapper aria-label={starsStrings[ratingStars - 1]}>
      {makeIndexArray(5).map((index) => (
        <StarIcon
          key={index}
          fill={index < ratingStars ? '#e0bc00' : '#e8dcc5'}
          color={index < ratingStars ? '#e0bc00' : '#e8dcc5'}
          size={12}
        />
      ))}
    </Wrapper>
  );
}
