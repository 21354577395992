import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ImageWrapper = styled.div`
  flex-shrink: 0;
  width: 150px;
  height: 150px;
  border-radius: var(--border-radius);
  overflow: hidden;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Name = styled.h3`
  margin: 0;
  font-size: 16px;
  font-weight: 700;
`;

export const RatingWrapper = styled.div`
  margin-top: 4px;
`;
