import { Navigate, useParams } from 'react-router-dom';
import { useAtom } from 'jotai';

import {
  Wrapper,
  ScrollContainer,
  ContentWrapper,
  Name,
  Description,
  Footer,
  ButtonWrapper,
} from './index.styles';

import HeaderWithButton from '../../../../header_with_button';
import ActivateButton from '../../main/coupons/activate_button';

import { vendorCardCouponQuery } from '../../../../../../../store';

export default function CouponPage() {
  const { coupon_id: couponId } = useParams();

  if (!couponId) {
    throw new Error('couponId is undefined');
  }

  const [coupon, setCoupon] = useAtom(vendorCardCouponQuery(couponId));

  if (!coupon) {
    return <Navigate to=".." />;
  }

  const { description, name } = coupon;

  return (
    <Wrapper>
      <ScrollContainer>
        <HeaderWithButton>Coupon</HeaderWithButton>
        <ContentWrapper>
          <Name>{name}</Name>
          {description ? <Description>{description}</Description> : null}
        </ContentWrapper>
      </ScrollContainer>
      <Footer>
        <ButtonWrapper>
          <ActivateButton coupon={coupon} setCoupon={setCoupon} />
        </ButtonWrapper>
      </Footer>
    </Wrapper>
  );
}
