import { useState } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import T from '@wojtekmaj/react-t';

import { CheckIcon, LockIcon } from 'lucide-react';

import Dialog from '@rewardopl/react-ui/dialog';

import colors from '@rewardopl/styles/colors';

import { ButtonWrapper } from '@rewardopl/react-ui/dialog/index.styles';
import {
  Coupon,
  ImageWrapper,
  Image,
  ImagePlaceholder,
  Icon,
  LockIconWrapper,
  Badge,
  Name,
  Description,
  Status,
  DetailsItem,
  DetailsItemContent,
  CodeWrapper,
} from './item.styles';

import ActivateButton from './activate_button';
import Code from '../../../../../../common/code';

import { formatShortDate } from '../../../../../../../utils/date';
import {
  formatCouponAvailability,
  formatNumber,
  formatPrice,
} from '../../../../../../../utils/helpers';

import getIsAvailable from './get_is_available';

import {
  vendorCardCouponActivationsQuery,
  vendorCardCouponQuery,
} from '../../../../../../../store';

import {
  COUPON_AVAILABILITY_TYPES,
  COUPON_BENEFIT_TYPES,
  COUPON_USAGE_TYPES,
} from '../../../../../../../constants';

import couponIcon from '../../../../../../../static/coupon.svg';

import type { Coupon as CouponType } from '@rewardopl/types';

type ItemCodeProps = {
  couponId: CouponType['_id'];
};

function ItemCode({ couponId }: ItemCodeProps) {
  const couponActivations = useAtomValue(vendorCardCouponActivationsQuery(couponId));

  const unusedCouponActivation = couponActivations.find(
    (couponActivation) => !couponActivation.is_used,
  );

  if (!unusedCouponActivation) {
    return null;
  }

  return (
    <CodeWrapper>
      <Code color={colors.text} id={unusedCouponActivation._id} />
    </CodeWrapper>
  );
}

type ItemProps = {
  couponId: CouponType['_id'];
};

export default function Item({ couponId }: ItemProps) {
  const [coupon, setCoupon] = useAtom(vendorCardCouponQuery(couponId));

  const {
    availability,
    benefits,
    description,
    is_activated: isActivated,
    name,
    picture,
    stats,
    usage,
  } = coupon;

  const { unused_activations: numberOfActivations } = stats;

  const [showDialog, setShowDialog] = useState(false);

  const open = () => {
    setShowDialog(true);
  };

  const close = () => {
    setShowDialog(false);
  };

  const isAvailable = getIsAvailable(coupon);

  const { type: availabilityType } = availability;

  return (
    <>
      <Coupon as="button" onClick={open} type="button">
        <ImageWrapper>
          {picture ? (
            <Image src={`/api/uploads/${picture}?w=${360 * devicePixelRatio}`} alt={name} />
          ) : (
            <ImagePlaceholder alt={name} src={couponIcon} />
          )}
        </ImageWrapper>
        {isAvailable ? null : (
          <Icon>
            <LockIconWrapper>
              <LockIcon color="var(--white)" size={14} />
            </LockIconWrapper>
          </Icon>
        )}
        <Badge>
          {(() => {
            const { type: benefitsType } = benefits;

            switch (benefitsType) {
              case COUPON_BENEFIT_TYPES.DISCOUNT_AMOUNT:
                return `-${formatPrice(benefits.amount)}`;
              case COUPON_BENEFIT_TYPES.DISCOUNT_PERCENTAGE:
                return `-${benefits.percentage}%`;
              case COUPON_BENEFIT_TYPES.STAMP_AMOUNT:
                return (
                  <>
                    {formatNumber(benefits.amount)} <T>points</T>
                  </>
                );
              case COUPON_BENEFIT_TYPES.STAMP_MULTIPLY:
                return `${benefits.multiplier}×`;
              case COUPON_BENEFIT_TYPES.FREEBIE:
                return benefits.freebie;
              default:
                throw new Error(`Invalid coupon benefits type: ${benefitsType satisfies never}`);
            }
          })()}
        </Badge>
        <Name>{name}</Name>
      </Coupon>
      <Dialog isOpen={showDialog} onDismiss={close} title={name}>
        {isActivated ? <ItemCode couponId={couponId} /> : null}
        {description ? <Description>{description}</Description> : null}
        <Status activated={isActivated} available={isAvailable}>
          {isAvailable ? (
            isActivated ? (
              <>
                <CheckIcon size={16} />
                {` ${numberOfActivations} × `}
                <T>Activated</T>
              </>
            ) : (
              <T>Not activated</T>
            )
          ) : (
            <T>Unavailable</T>
          )}
        </Status>
        {isAvailable ? null : (
          <LockIconWrapper>
            <LockIcon color="var(--white)" size={14} />
          </LockIconWrapper>
        )}
        {isActivated ? null : (
          <DetailsItem>
            <DetailsItemContent>
              {(() => {
                switch (usage.type) {
                  case COUPON_USAGE_TYPES.ONCE:
                    return <T>This coupon can be activated only once.</T>;
                  case COUPON_USAGE_TYPES.ONCE_EVERY:
                    return (
                      <>
                        <T>This coupon can be activated once per</T> {usage.value}{' '}
                        <T>{`${usage.unit}${usage.value === 1 ? '' : 's'}`}</T>
                      </>
                    );
                  case COUPON_USAGE_TYPES.UNLIMITED:
                    return <T>This coupon can be activated unlimited number of times.</T>;
                  default:
                    throw new Error('Invalid coupon usage type');
                }
              })()}
            </DetailsItemContent>
          </DetailsItem>
        )}
        <DetailsItem>
          <DetailsItemContent>
            <T>Availability</T>:
          </DetailsItemContent>
          {(() => {
            switch (availabilityType) {
              case COUPON_AVAILABILITY_TYPES.DATETIME_RANGE:
                return `${formatShortDate(availability.from)} - ${formatShortDate(
                  availability.to,
                )}`;
              case COUPON_AVAILABILITY_TYPES.HOURS:
                return formatCouponAvailability(availability.hours);
              case COUPON_AVAILABILITY_TYPES.UNLIMITED:
                return <T>No time limit</T>;
              default:
                throw new Error(
                  `Invalid coupon availability type: ${availabilityType satisfies never}`,
                );
            }
          })()}
        </DetailsItem>
        {isAvailable ? (
          <ButtonWrapper>
            <ActivateButton coupon={coupon} setCoupon={setCoupon} />
          </ButtonWrapper>
        ) : null}
      </Dialog>
    </>
  );
}
