import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAtomValue } from 'jotai';

import UrgentMessagesMonitorDialog from './dialog';

import { vendorUrgentMessagesState } from '../../../../store';

import type { Message } from '@rewardopl/types';

const MAX_MESSAGES = 3;

export default function UrgentMessagesMonitor() {
  const location = useLocation();
  const navigate = useNavigate();
  const urgentMessages = useAtomValue(vendorUrgentMessagesState);
  const [messageForDisplay, setMessageForDisplay] = useState<Message | null>(null);

  const closeDialogAndWaitForDestroyed = useCallback<() => Promise<void>>(
    () =>
      new Promise((resolve) => {
        if (!location.hash) {
          resolve();
          return;
        }

        navigate(
          {
            ...location,
            hash: '',
          },
          { state: location.state },
        );

        setTimeout(resolve, 1000);
      }),
    [location, navigate],
  );

  useEffect(() => {
    if (messageForDisplay) {
      return;
    }

    const nextMessageForDisplay = urgentMessages
      .slice(0, MAX_MESSAGES)
      .find((message) => !message.is_read);

    if (nextMessageForDisplay) {
      closeDialogAndWaitForDestroyed().then(() => {
        setMessageForDisplay(nextMessageForDisplay);
      });
    }
  }, [closeDialogAndWaitForDestroyed, messageForDisplay, urgentMessages]);

  if (!messageForDisplay) {
    return null;
  }

  function closeDialog() {
    setMessageForDisplay(null);
  }

  return <UrgentMessagesMonitorDialog onDismiss={closeDialog} message={messageForDisplay} />;
}
