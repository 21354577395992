import { useNavigate } from 'react-router-dom';
import { useTranslation } from '@wojtekmaj/react-t';

import { ChevronLeftIcon } from 'lucide-react';

import { Button } from './back_button.styles';

type BackButtonProps = Omit<React.ComponentProps<typeof Button>, 'to' | 'title'>;

export default function BackButton({ ...otherProps }: BackButtonProps) {
  const navigate = useNavigate();
  const backString = useTranslation('Back');

  return (
    <Button {...otherProps} onClick={() => navigate(-1)} title={backString}>
      <ChevronLeftIcon size={28} />
    </Button>
  );
}
