import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  form {
    display: contents;
  }
`;

export const Question = styled.p`
  margin: 0;
  margin-top: 16px;
  font-size: 16px;
  text-align: center;
`;

export const FeedbackWrapper = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
`;
