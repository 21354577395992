import { useAtomValue } from 'jotai';
import { Link } from 'react-router-dom';
import T from '@wojtekmaj/react-t';

import {
  Reward,
  ImageWrapper,
  Image,
  ImagePlaceholder,
  ContentWrapper,
  Name,
  Price,
} from './item.styles';

import { formatNumber } from '../../../../../../../utils/helpers';

import { vendorCardSubscriptionState } from '../../../../../../../store';

import lockIcon from '../../../../../../../static/lock.svg';
import rewardIcon from '../../../../../../../static/reward.svg';

import type { Reward as RewardType } from '@rewardopl/types';

type ItemProps = {
  reward: RewardType;
};

export default function Item({ reward }: ItemProps) {
  const cardSubscription = useAtomValue(vendorCardSubscriptionState);

  const { balance } = cardSubscription;

  const { _id: rewardId, name, picture, price } = reward;

  const isAvailable = balance >= price;

  return (
    <Reward as={Link} to={`/rewards/${rewardId}`}>
      <ImageWrapper>
        {picture ? (
          <Image alt={name} src={`/api/uploads/${picture}?w=${118 * devicePixelRatio}`} />
        ) : (
          <ImagePlaceholder alt={name} src={rewardIcon} />
        )}
      </ImageWrapper>
      <ContentWrapper>
        <Price>
          {isAvailable ? null : <img alt="" src={lockIcon} />}
          {formatNumber(price)} <T>{`point${price === 1 ? '' : 's'}`}</T>
        </Price>
        <Name>{name}</Name>
      </ContentWrapper>
    </Reward>
  );
}
