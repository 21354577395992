import { getIsOpenNow } from '@wojtekmaj/opening-hours-utils';

import { COUPON_AVAILABILITY_TYPES } from '../../../../../../../constants';

import type { Coupon } from '@rewardopl/types';

export default function getIsAvailable(coupon: Coupon) {
  const { availability } = coupon;

  const { type: availabilityType } = availability;

  switch (availabilityType) {
    case COUPON_AVAILABILITY_TYPES.DATETIME_RANGE: {
      const now = new Date();

      return now >= new Date(availability.from) && now <= new Date(availability.to);
    }
    case COUPON_AVAILABILITY_TYPES.HOURS: {
      const isActive = getIsOpenNow(availability.hours);

      return isActive;
    }
    case COUPON_AVAILABILITY_TYPES.UNLIMITED:
      return true;
    default:
      throw new Error(`Invalid coupon availability type: ${availabilityType satisfies never}`);
  }
}
