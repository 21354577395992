import styled from 'styled-components';

export const Button = styled.button`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  &:hover, &:focus-within {
    ${Button} {
      &:hover, &:focus {
        & ~ ${Button} {
          img, svg {
            fill: #d9ceb8;
            stroke: #d9ceb8;
          }
        }
      }

      &:not(:hover ~ ${Button}):not(:focus ~ ${Button}) {
        img, svg {
          fill: #e0bc00;
          stroke: #e0bc00;
        }
      }
    }
  }
`;
