import styled from 'styled-components';

import { buttonWrapperStyle } from '@rewardopl/react-ui/button_wrapper';
import { visuallyHidden } from '@rewardopl/react-ui/visually_hidden/index.styles';

export const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  background: var(--background);
  display: flex;
  flex-direction: column;

  position: fixed;
  inset: 0;

  @media (screen-spanning: single-fold-vertical) {
    width: env(fold-left);
  }

  @media (horizontal-viewport-segments: 2) {
    width: env(viewport-segment-right 0 0);
  }
`;

export const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const Title = styled.h1`
  ${visuallyHidden}
`;

export const ButtonWrapper = styled.div`
  ${buttonWrapperStyle}
  width: 100%;
  max-width: 375px;
  margin: 0 auto;
  padding: var(--gap);
  padding-top: 0;

  a,
  button {
    flex-grow: 1;
  }
`;
