import { Routes, Route } from 'react-router-dom';

import CouponsMain from './main';
import CouponPage from './coupon';

export default function CouponsPage() {
  return (
    <Routes>
      <Route path="" element={<CouponsMain />} />
      <Route path=":coupon_id/*" element={<CouponPage />} />
    </Routes>
  );
}
