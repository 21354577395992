import { useAtomValue } from 'jotai';

import { ItemWrapper } from './index.styles';

import Items from '../../main/rewards/items';

import PageWrapper from '../../../../../../page_wrapper';
import Heading from '../../../../../../heading';

import { vendorCardRewardsState } from '../../../../../../../store';

export default function RewardsMain() {
  const cardRewards = useAtomValue(vendorCardRewardsState);

  return (
    <PageWrapper>
      <Heading align="center">Rewards</Heading>
      <ItemWrapper>
        <Items items={cardRewards} />
      </ItemWrapper>
    </PageWrapper>
  );
}
