import { Wrapper, Image } from './splash_screen.styles';

import logo from '../static/splash/logo.svg';

import { VENDOR_BUSINESS_NAME } from '../constants';

export default function SplashScreen() {
  return (
    <Wrapper>
      <Image src={logo} alt={VENDOR_BUSINESS_NAME} />
    </Wrapper>
  );
}
