import { useLocation, useNavigate } from 'react-router-dom';
import T, { useTranslation } from '@wojtekmaj/react-t';
import AsyncButton from '@wojtekmaj/react-async-button';

import { toast } from '@rewardopl/react-toast';

import Button from '@rewardopl/react-ui/button';
import InputField from '@rewardopl/react-ui/input_field';

import { post } from '@rewardopl/utils/network';

import { Form, ButtonWrapper } from './form.styles';

const pendingConfig = {
  disabled: true,
};

export default function LoginTwoStepEmailStepEmailForm() {
  const location = useLocation();
  const navigate = useNavigate();
  const loginErrorString = useTranslation('Failed to log in');

  const { email, returnPath = '/' } = (location.state || {}) as {
    email?: string;
    returnPath?: string;
  };

  const action1 = '/api/users/check';

  const action2 = '/api/users/password_reset';

  async function onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();

    const submitButton = event.currentTarget;
    const form = submitButton.form;

    if (!form || !form.reportValidity()) {
      return;
    }

    const formData = new FormData(form);

    try {
      const { exists, needs_password_reset: needsPasswordReset } = (await post(action1, {
        body: formData,
      })) as { exists: boolean; needs_password_reset: boolean };

      if (!exists) {
        navigate('/register_with_details', { state: { email: formData.get('email'), returnPath } });
        return;
      }

      if (needsPasswordReset) {
        await post(action2, {
          body: formData,
        });

        navigate('/password_reset_confirmation', { state: { email: formData.get('email') } });
        return;
      }

      navigate('../password', { state: { email: formData.get('email'), returnPath } });
    } catch {
      toast.error(loginErrorString);
    }
  }

  return (
    <Form action={action1} method="POST">
      <InputField
        autoComplete="username"
        value={email}
        label="Email"
        maxLength={320}
        name="email"
        placeholder="email@example.com"
        type="email"
      />
      <ButtonWrapper>
        <AsyncButton
          as={Button}
          className="primary"
          onClick={onClick}
          pendingConfig={pendingConfig}
          type="submit"
        >
          <T>Continue</T>
        </AsyncButton>
      </ButtonWrapper>
    </Form>
  );
}
