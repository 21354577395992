import saladStoryLogo from '../../static/saladstory-mono.svg';

import { Wrapper, Image } from './vendor_logo.styles';

import { VENDOR_BUSINESS_NAME } from '../../constants';

type VendorLogoProps = { width?: number };

export default function VendorLogo({ width = 180 }: VendorLogoProps) {
  const height = width * (90 / 366);

  return (
    <Wrapper>
      <Image width={width} height={height} src={saladStoryLogo} alt={VENDOR_BUSINESS_NAME} />
    </Wrapper>
  );
}
