import { useAtomValue } from 'jotai';

import { ItemWrapper } from './index.styles';

import Items from '../../main/offers/items';

import PageWrapper from '../../../../../../page_wrapper';
import Heading from '../../../../../../heading';

import { vendorCardOffersState } from '../../../../../../../store';

export default function OffersMain() {
  const cardOffers = useAtomValue(vendorCardOffersState);

  return (
    <PageWrapper>
      <Heading align="center">News</Heading>
      <ItemWrapper>
        <Items items={cardOffers} />
      </ItemWrapper>
    </PageWrapper>
  );
}
