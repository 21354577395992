import styled from 'styled-components';

export const Button = styled.button`
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -12px;
  padding: 0;
  background: none;
  border: 0;
  align-self: flex-start;
  color: inherit;
`;
