import { Routes, Route } from 'react-router-dom';

import OffersMain from './main';
import OfferPage from './offer';

export default function OffersPage() {
  return (
    <Routes>
      <Route path="" element={<OffersMain />} />
      <Route path=":offer_id/*" element={<OfferPage />} />
    </Routes>
  );
}
