import styled from 'styled-components';

import { buttonWrapperStyle } from '@rewardopl/react-ui/button_wrapper';

import { dialog } from '@rewardopl/styles/z-index';

import { ContentWrapper as TermsAndConditionsContentWrapper } from '../../../../../terms_and_conditions.styles';

export const Wrapper = styled.div.attrs<{ children: React.ReactNode }, { 'data-dialog': boolean }>({
  'data-dialog': true,
})`
  position: fixed;
  inset: 0;

  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  display: flex;
  flex-direction: column;
  background: var(--background);
  z-index: ${dialog};

  @media (screen-spanning: single-fold-vertical) {
    left: calc(env(fold-left) + env(fold-width));
  }

  @media (horizontal-viewport-segments: 2) {
    left: env(viewport-segment-left 1 0);
  }
`;

export const ScrollContainer = styled(TermsAndConditionsContentWrapper)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0;
  overflow: auto;
`;

export const ContentWrapper = styled.div`
  flex-grow: 1;
  padding: var(--gap) 16px;

  @media screen and (min-width: 769px) {
    padding: var(--gap);
  }
`;

export const Heading = styled.h1`
  font-size: 24px;
  text-align: center;
`;

export const Description = styled.p`
  line-height: 1.5;
  white-space: pre-wrap;
`;

export const Footer = styled.div`
  box-shadow: var(--shadow-1-reverse);
`;

export const ButtonWrapper = styled.div`
  ${buttonWrapperStyle}
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  padding: var(--gap);

  a,
  button {
    flex-grow: 1;
  }
`;
