import T from '@wojtekmaj/react-t';

import LinkButton from '@rewardopl/react-ui/link_button';

import { PageWrapper, ContentWrapper, Image, Title, Sub } from './index.styles';

import VendorLogo from '../../common/vendor_logo';

import padlockIcon from '../../../static/padlock.svg';

type NoAccessPageProps = {
  state?: object;
};

export default function NoAccessPage({ state }: NoAccessPageProps) {
  return (
    <PageWrapper>
      <ContentWrapper>
        <VendorLogo width={164} />
        <Image width={124} height={124} src={padlockIcon} alt="" />
        <Title>
          <T>No access</T>
        </Title>
        <Sub>
          <T>This page is for logged in users only.</T>
        </Sub>
        <p>
          <LinkButton className="primary" to="/login" state={state}>
            <T>Login</T>
          </LinkButton>
        </p>
        <p>
          <LinkButton to="/register_with_details" state={state}>
            <T>Register</T>
          </LinkButton>
        </p>
      </ContentWrapper>
    </PageWrapper>
  );
}
