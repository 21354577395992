import styled from 'styled-components';

import { buttonWrapperStyle } from '@rewardopl/react-ui/button_wrapper';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ImageWrapper = styled.div`
  width: 320px;
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const ImagePlaceholder = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const Name = styled.h2`
  margin: 0;
  margin-top: 8px;
  font-size: 24px;
  text-align: center;
`;

export const Price = styled.p`
  height: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin: 0;
  margin-top: 8px;
  padding: 2.5px 8px 1.5px;
  border: 1px solid var(--green-dark);
  border-radius: 8px;
  font-weight: 700;
  line-height: 12px;
  overflow: hidden;
`;

export const Description = styled.p`
  margin: 0;
  margin-top: 18px;
  white-space: pre-wrap;
  text-align: center;
`;

export const Hint = styled.p`
  margin: 0;
  margin-top: 18px;
  font-size: 12px;
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  ${buttonWrapperStyle}
  width: 100%;
  max-width: 320px;
  margin-top: var(--gap);

  a,
  button {
    flex-grow: 1;
  }
`;
