import styled from 'styled-components';

import { Tile } from '../../../../../../common/scrollable_tiles.styles';

import Section from '../../../../../../section.styles';

export const Coupon = styled(Tile)`
  width: 100%;
  min-height: 200px;
  padding: 0;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  @media screen and (min-width: 768px) {
    width: 360px;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ImagePlaceholder = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Icon = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
`;

export const LockIconWrapper = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  padding: 5px;
  background: black;
  border-radius: 50%;
  line-height: 1;
`;

export const Badge = styled.div`
  display: inline-block;
  min-width: 30px;
  height: 30px;
  position: absolute;
  top: 25px;
  right: 0;
  padding: 4px 8px 4px 15px;
  background: var(--green-dark);
  border-radius: 15px 0 0 15px;
  color: var(--beige-light);
  font-size: 16px;
  font-weight: 700;
  line-height: 23px;
`;

export const Name = styled.p`
  position: absolute;
  bottom: 16px;
  left: 16px;
  margin: 0;
  font-size: 16px;
  font-weight: 700;
`;

export const Description = styled.p`
  flex-grow: 1;
  margin: 0;
  margin-bottom: 32px;
  white-space: pre-wrap;
`;

export const Status = styled.p<{ available: boolean; activated: boolean }>`
  margin: 0;
  color: ${(props) =>
    props.available
      ? props.activated
        ? 'var(--success-dark)'
        : 'var(--primary)'
      : 'var(--text-light)'};
  font-weight: 500;

  svg {
    position: relative;
    top: 3px;
  }
`;

export const Availability = styled.div`
  display: flex;
  margin: 10px -8px -8px -8px;
  padding: 10px 16px;
  border-top: 1px dashed var(--stroke);
  line-height: 1.5;
  gap: 8px;

  svg {
    flex-shrink: 0;
    position: relative;
    top: 3px;
  }
`;

export const AvailabilityContent = styled.div`
  margin: 0;
  font-size: 12px;
  color: var(--text-light);
`;

export const DetailsItem = styled.div``;

export const DetailsItemContent = styled.p``;

export const CodeWrapper = styled(Section)`
  margin: 16px 12px;
`;
