import Item from './item';

import type { Reward } from '@rewardopl/types';

type ItemsProps = {
  items: Reward[];
};

export default function Items({ items }: ItemsProps) {
  return [...items]
    .sort((a, b) => a.price - b.price)
    .map((reward) => {
      return <Item key={reward._id} reward={reward} />;
    });
}
