import { useNavigate } from 'react-router-dom';
import T, { useTranslation } from '@wojtekmaj/react-t';
import AsyncButton from '@wojtekmaj/react-async-button';

import { toast } from '@rewardopl/react-toast';

import Button from '@rewardopl/react-ui/button';
import InputField from '@rewardopl/react-ui/input_field';

import { patch } from '@rewardopl/utils/network';

import { Form, ButtonWrapper } from '../login/email/form.styles';

import type { User } from '@rewardopl/types';

const pendingConfig = {
  disabled: true,
};

type PasswordResetFormProps = {
  id: User['_id'];
  token: NonNullable<User['password_reset_token']>;
};

export default function PasswordResetForm({ id, token }: PasswordResetFormProps) {
  const navigate = useNavigate();
  const userDidNotRequestPasswordResetString = useTranslation(
    'Password reset has not been requested or password reset link has already been used',
  );
  const tokenIsInvalidString = useTranslation(
    'Password reset link is invalid. Check if you copied the link correctly',
  );
  const tokenIsExpiredString = useTranslation(
    'Password reset link has expired. Request password reset again',
  );
  const passwordConfirmInvalidString = useTranslation(
    'New password and New password (confirm) do not match',
  );
  const genericErrorString = useTranslation('Failed to reset password');

  const action = `/api/users/${id}`;

  async function onClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();

    const submitButton = event.currentTarget;
    const form = submitButton.form;

    if (!form || !form.reportValidity()) {
      return;
    }

    try {
      await patch(action, {
        body: new FormData(form),
      });

      navigate('/login');
    } catch (err) {
      const error = err;

      const errorString = (() => {
        if (!(error instanceof Error)) {
          return genericErrorString;
        }

        switch (error.message) {
          case 'User did not request password reset':
            return userDidNotRequestPasswordResetString;
          case 'token is invalid':
            return tokenIsInvalidString;
          case 'token is expired':
            return tokenIsExpiredString;
          case 'passwords do not match':
            return passwordConfirmInvalidString;
          default:
            return genericErrorString;
        }
      })();

      toast.error(errorString);
    }
  }

  return (
    <Form action={action} method="POST">
      <input name="_id" value={id} type="hidden" />
      <input name="token" value={token} type="hidden" />
      <InputField
        autoComplete="new-password"
        label="New password"
        name="new_password"
        type="password"
      />
      <InputField
        autoComplete="new-password"
        label="New password (confirm)"
        name="new_password_confirm"
        type="password"
      />
      <ButtonWrapper>
        <AsyncButton
          as={Button}
          className="primary"
          onClick={onClick}
          pendingConfig={pendingConfig}
          type="submit"
        >
          <T>Save</T>
        </AsyncButton>
      </ButtonWrapper>
    </Form>
  );
}
