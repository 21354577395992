import { Wrapper, Image } from './header.styles';

import saladPng from '../../../static/salad.png';
import saladPng2 from '../../../static/salad@2x.png';
import saladWebp from '../../../static/salad.webp';
import saladWebp2 from '../../../static/salad@2x.webp';
import saladAvif from '../../../static/salad.avif';
import saladAvif2 from '../../../static/salad@2x.avif';

export default function Header() {
  return (
    <Wrapper>
      <picture>
        <source srcSet={`${saladAvif}, ${saladAvif2} 2x`} type="image/avif" />
        <source srcSet={`${saladWebp}, ${saladWebp2} 2x`} type="image/webp" />
        <source srcSet={`${saladPng}, ${saladPng2} 2x`} type="image/png" />
        <Image alt="" src={saladPng} width={375} height={225} fetchPriority="high" />
      </picture>
    </Wrapper>
  );
}
