import { useEffect, useMemo, useRef, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { animated, useSpring } from '@react-spring/web';
import { useWindowWidth } from '@wojtekmaj/react-hooks';
import T from '@wojtekmaj/react-t';

import { HomeIcon, MoreHorizontalIcon, SaladIcon, ScanBarcodeIcon, TruckIcon } from 'lucide-react';

import {
  Wrapper,
  List,
  ListItem,
  ListItemLink,
  ListItemUnderlay,
  TabIndicator,
  Icon,
  Label,
} from './menu.styles';

import type { LucideIcon } from 'lucide-react';

const AnimatedTabIndicator = animated(TabIndicator);

type Item = {
  label: string;
  icon: LucideIcon;
  href: string;
  exact?: boolean;
  primary?: boolean;
};

type ItemWithFlags = Item & { active: boolean };

const items = [
  {
    label: 'Home',
    icon: HomeIcon,
    href: '/',
    exact: true,
  },
  {
    label: 'Menu',
    icon: SaladIcon,
    href: 'products',
  },
  {
    label: 'My card',
    icon: ScanBarcodeIcon,
    href: '#your-code',
    primary: true,
  },
  {
    label: 'Delivery',
    icon: TruckIcon,
    href: 'delivery',
  },
  {
    label: 'More',
    icon: MoreHorizontalIcon,
    href: 'more',
  },
] satisfies Item[];

export default function Menu() {
  const windowWidth = useWindowWidth();
  const location = useLocation();
  const tabWrapper = useRef<HTMLUListElement>(null);
  const shouldUseImmediate = useRef(true);
  const [indicatorRect, setIndicatorRect] = useState<object>({ left: 0, opacity: 1 });

  const props = useSpring(indicatorRect);

  const itemsWithFlags = useMemo<ItemWithFlags[]>(
    () =>
      items.map((item) => {
        if (item.href.startsWith('#')) {
          return {
            ...item,
            active: location.hash === item.href,
          };
        }

        const match = matchPath(
          item.href,
          item.exact ? location.pathname : `/${location.pathname.split('/')[1]}`,
        );
        const active = Boolean(match);

        return { ...item, active };
      }),
    [location.hash, location.pathname],
  );

  const activeItem = itemsWithFlags.find((item) => item.active);

  // biome-ignore lint/correctness/useExhaustiveDependencies: useEffect intentionally triggered on window width change
  useEffect(() => {
    shouldUseImmediate.current = true;
  }, [windowWidth]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: useEffect intentionally triggered on location change
  useEffect(() => {
    if (!tabWrapper.current) {
      return;
    }

    const activeTab = tabWrapper.current.querySelector(`[href$="${activeItem?.href}"]`);

    function getRect() {
      if (!tabWrapper.current) {
        return;
      }

      if (!activeTab) {
        setIndicatorRect({
          opacity: 0,
          immediate: true,
        });

        shouldUseImmediate.current = true;

        return;
      }

      const rect = activeTab.getBoundingClientRect();

      if (!rect.width && !rect.height) {
        requestAnimationFrame(getRect);
        return;
      }

      const parentRect = tabWrapper.current.getBoundingClientRect();

      const immediate = shouldUseImmediate.current;

      setIndicatorRect({
        left: rect.x - parentRect.x + rect.width / 2,
        opacity: 1,
        immediate,
      });

      shouldUseImmediate.current = false;
    }

    getRect();
  }, [activeItem, location.pathname, windowWidth]);

  return (
    <Wrapper>
      <List itemCount={itemsWithFlags.length} ref={tabWrapper} role="list">
        {itemsWithFlags.map((item) => {
          const IconComponent = item.icon;

          return (
            <ListItem key={item.label}>
              <ListItemLink
                active={item.active}
                primary={item.primary}
                to={item.href}
                end={item.exact}
              >
                <ListItemUnderlay />
                <Icon active={item.active} primary={item.primary}>
                  <IconComponent />
                </Icon>
                <Label>
                  <T>{item.label}</T>
                </Label>
              </ListItemLink>
            </ListItem>
          );
        })}
      </List>
      <AnimatedTabIndicator style={props} />
    </Wrapper>
  );
}
