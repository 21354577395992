import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import T, { useTranslation } from '@wojtekmaj/react-t';

import { toast } from '@rewardopl/react-toast';

import Button from '@rewardopl/react-ui/button';
import CheckboxField from '@rewardopl/react-ui/checkbox_field';

import { patch } from '@rewardopl/utils/network';

import {
  Wrapper,
  ScrollContainer,
  ContentWrapper,
  Heading,
  Footer,
  ButtonWrapper,
} from '../accept_terms_and_conditions/index.styles';

import { Indent } from '../../../../login/email/form.styles';

import MarketingDisclaimers from '../../../../../common/marketing_disclaimers';
import TermsAndConditionsLink from '../../../../../terms_and_conditions_link';
import PrivacyPolicyLink from '../../../../../privacy_policy_link';

import { currentUserState } from '../../../../../../store';

import type { User } from '@rewardopl/types';

export default function AppConsentsPage() {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useAtom(currentUserState);
  const acceptTermsErrorString = useTranslation('Failed to accept Terms and Conditions');

  const [emailMarketingAccepted, setEmailMarketingAccepted] = useState(false);
  const [smsMarketingAccepted, setSmsMarketingAccepted] = useState(false);
  const marketingAccepted = emailMarketingAccepted || smsMarketingAccepted;

  const { terms_accepted: termsAccepted } = currentUser;

  if (termsAccepted) {
    return <Navigate to=".." />;
  }

  const { _id: userId } = currentUser;

  const action = `/api/users/${userId}`;

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const form = event.currentTarget;

    if (!form || !form.reportValidity()) {
      return;
    }

    const formData = new FormData(form);

    try {
      const user = (await patch(action, {
        body: formData,
      })) as User;

      setCurrentUser(user);

      navigate('../');
    } catch {
      toast.error(acceptTermsErrorString);
    }
  }

  return (
    <form action={action} method="PATCH" onSubmit={onSubmit}>
      <Wrapper>
        <ScrollContainer>
          <ContentWrapper>
            <Heading>
              <T>Terms and Conditions</T>
            </Heading>
            <CheckboxField
              name="terms_accepted"
              label={
                <>
                  <T>I have read and agree to the</T> <TermsAndConditionsLink /> <T>and</T>{' '}
                  <PrivacyPolicyLink />.
                </>
              }
              required
            />
            <CheckboxField
              checked={marketingAccepted}
              name="marketing_accepted"
              label={
                <T>
                  I consent to Salad Story S.A. providing information about the latest promotions,
                  products and services of its own and about the promotions, products and services
                  of its partners via e-mail or push messages.
                </T>
              }
              onChange={() => {
                setEmailMarketingAccepted(!marketingAccepted);
                setSmsMarketingAccepted(!marketingAccepted);
              }}
            />
            <Indent>
              <CheckboxField
                checked={smsMarketingAccepted}
                label={<T>SMS</T>}
                name="marketing_sms_accepted"
                onChange={() => {
                  setSmsMarketingAccepted(!smsMarketingAccepted);
                }}
              />
              <CheckboxField
                checked={emailMarketingAccepted}
                name="marketing_email_accepted"
                label={<T>E-mail</T>}
                onChange={() => {
                  setEmailMarketingAccepted(!emailMarketingAccepted);
                }}
              />
            </Indent>
            <MarketingDisclaimers />
          </ContentWrapper>
        </ScrollContainer>
        <Footer>
          <ButtonWrapper>
            <Button className="primary" type="submit">
              <T>Continue</T>
            </Button>
          </ButtonWrapper>
        </Footer>
      </Wrapper>
    </form>
  );
}
