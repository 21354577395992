import { post } from '@rewardopl/utils/network';

import LikeButton from './like_button';

import type { PlaceWithDynamicPropertiesForUser } from '@rewardopl/types';

type PlaceLikeButtonProps = {
  place: PlaceWithDynamicPropertiesForUser;
  setPlace: (place: PlaceWithDynamicPropertiesForUser) => void;
  showLabel?: boolean;
};

export default function PlaceLikeButton({ place, setPlace, showLabel }: PlaceLikeButtonProps) {
  const { is_liked_by_current_user: isLiked } = place;

  const action = `/api/places/${place._id}/${isLiked ? 'unlike' : 'like'}`;

  async function onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();

    const response = await post(action);

    setPlace(response as PlaceWithDynamicPropertiesForUser);
  }

  return <LikeButton isLiked={isLiked} onClick={onClick} showLabel={showLabel} />;
}
