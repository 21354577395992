import { Wrapper, Arch, ContentWrapper } from './nav.styles';

import RegisterBanner from './register_banner';
import Menu from './menu';

import arch from '../../../static/arch.svg';

export default function Nav() {
  return (
    <Wrapper>
      <Arch width={400} height={12} src={arch} alt="" />
      <ContentWrapper>
        <RegisterBanner />
        <Menu />
      </ContentWrapper>
    </Wrapper>
  );
}
