import { alpha, mixBlack, mixWhite } from '@wojtekmaj/color-utils';

const brandColors = {
  lime: '#489c2d',
  yellow: '#f7a600',
  pink: '#e50052',
  green: '#004621',
};

const colors = {
  'active-menu-item': '#f5e8d1',
  accent: brandColors.pink,
  background: '#ebdfc8',
  'background-2': '#f2faef',
  'card-background': '#f5e8d1',
  'dialog-background': '#ebdfc8',
  'fab-background': '#008740',
  'featured-section-background': '#c7ecbc',
  'header-background': '#fffdfa',
  icon: brandColors.green,
  'icon-reverse': '#fffdfa',
  link: '#40714a',
  'menu-background': '#2d4932',
  overlay: alpha('#004621', 0.05),
  'overlay-fallback': mixWhite('#004621', 0.05),
  'primary-button-background': '#2d4932',
  'primary-button-text': '#ebdfc8',
  'secondary-button-background': '#f5e8d1',
  'secondary-button-border-color': '#2d4932',
  'secondary-button-text': '#2d4932',
  shadow: alpha(brandColors.green, 0.05),
  stroke: '#2d4932',
  text: '#2d4932',
  'text-light': '#004621',
  'text-muted': alpha('#004621', 0.25),
  'text-reverse': '#ffffff',

  beige: '#ebdfc8',
  'beige-light': '#f5e8d1',
  'green-dark': '#2d4932',
  'green-light': '#a3b169',
  'green-medium': '#3d6344',

  /** @deprecated */
  primary: '#40714a',
  /** @deprecated */
  'primary-dark': mixBlack('#40714a', 0.8),
  /** @deprecated */
  'primary-light': mixWhite('#40714a', 0.8),
  /** @deprecated */
  secondary: '#36746e',
  /** @deprecated */
  'secondary-dark': '#0f3a39',
  /** @deprecated */
  'secondary-light': '#95d4cb',
  /** @deprecated */
  green: '#7fc4bd',
  /** @deprecated */
  orange: '#d96032',
  /** @deprecated */
  'pink-light': '#fadce9',
  /** @deprecated */
  'text-on-white': '#0f3a39',
  /** @deprecated */
  'text-on-white-light': alpha('#0f3a39', 0.75),
  /** @deprecated */
  'text-on-white-muted': alpha('#0f3a39', 0.25),
  /** @deprecated */

  /* Inherited colors */
  /** @deprecated */
  black: '#231f20',
  /** @deprecated */
  'error-dark': '#c50606',
  /** @deprecated */
  'error-light': '#ff746b',
  /** @deprecated */
  'error-background': alpha('#ff2323', 0.1),
  /** @deprecated */
  gray: '#c9d9e8',
  /** @deprecated */
  'gray-light': '#f2f6f8',
  /** @deprecated */
  'info-dark': '#026f9c',
  /** @deprecated */
  'info-light': '#2ec1ff',
  /** @deprecated */
  'info-background': alpha('#00b4ff', 0.1),
  /** @deprecated */
  'orange-light': '#fbc288',
  /** @deprecated */
  'success-dark': '#106d1f',
  /** @deprecated */
  'success-light': '#74d884',
  /** @deprecated */
  'success-background': alpha('#2aac3f', 0.1),
  /** @deprecated */
  'warning-dark': '#85722f',
  /** @deprecated */
  'warning-light': '#ffdd66',
  /** @deprecated */
  'warning-background': alpha('#ffdd66', 0.15),
  /** @deprecated */
  white: '#ffffff',
} as const;

export default colors;
