import { Link } from 'react-router-dom';

import { Offer, ImageWrapper, Image } from './item.styles';

import type { Offer as OfferType } from '@rewardopl/types';

export default function Item({ offer }: { offer: OfferType }) {
  const { _id: offerId, name, picture } = offer;

  return (
    <Offer as={Link} title={name} to={`/offers/${offerId}`}>
      <ImageWrapper>
        {picture ? (
          <Image alt={name} src={`/api/uploads/${picture}?w=${360 * devicePixelRatio}`} />
        ) : null}
      </ImageWrapper>
    </Offer>
  );
}
