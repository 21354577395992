import styled, { css } from 'styled-components';

import { navigation } from '@rewardopl/styles/z-index';

import { Wrapper as VendorLogo } from '../../common/vendor_logo.styles';

export const Wrapper = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  grid-area: aside;
  margin-bottom: calc(env(safe-area-inset-bottom) * -1);
  ${() =>
    navigator.standalone &&
    css`
      margin-bottom: -15px;
      margin-bottom: calc(max(15px, env(safe-area-inset-bottom)) * -1);
    `}
  padding-bottom: env(safe-area-inset-bottom);
  ${() =>
    navigator.standalone &&
    css`
      padding-bottom: 15px;
      padding-bottom: max(15px, env(safe-area-inset-bottom));
    `}
  padding-left: env(safe-area-inset-left);
  background: var(--menu-background);
  position: relative;
  z-index: ${navigation};

  ${VendorLogo} {
    display: none;
  }

  @media screen and (min-width: 769px) {
    width: 100%;
    padding: 15px;
    padding-left: max(15px, env(safe-area-inset-left));
    overflow: auto;

    ${VendorLogo} {
      display: block;
      margin: 5px auto calc(var(--gap) + 5px);
    }
  }
`;

export const Arch = styled.img`
  width: 100%;
  height: 12px;
  margin-top: -12px;

  @media screen and (min-width: 769px) {
    display: none;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
`;
