import styled from 'styled-components';

import {
  Wrapper,
  ScrollContainer,
  ContentWrapper as AcceptTermsAndConditionsContentWrapper,
  Footer,
  ButtonWrapper as AcceptTermsAndConditionsButtonWrapper,
} from '../../accept_terms_and_conditions/index.styles';

export { Wrapper, ScrollContainer, Footer };

export const ContentWrapper = styled(AcceptTermsAndConditionsContentWrapper)`
  text-align: center;
`;

export const Name = styled.div`
  font-size: 24px;
  font-weight: 700;
`;

export const Description = styled.p`
  margin: 3em auto 2em;
  white-space: pre-wrap;
`;

export const ButtonWrapper = styled(AcceptTermsAndConditionsButtonWrapper)`
  a,
  button {
    flex-grow: 0;
  }
`;
