import { useTranslation } from '@wojtekmaj/react-t';
import { StarIcon } from 'lucide-react';

import { makeIndexArray } from '@rewardopl/utils/array';

import { Wrapper, Button } from './rating_button.styles.js';

type RatingButtonProps = {
  numberOfStars?: number;
  onChange: (numberOfStars: number) => void;
  rating?: number;
};

export default function RatingButton({
  numberOfStars = 5,
  onChange,
  rating = 0,
}: RatingButtonProps) {
  const oneStarString = useTranslation('1 star');
  const twoStarsString = useTranslation('{count} stars', { count: 2 });
  const threeStarsString = useTranslation('{count} stars', { count: 3 });
  const fourStarsString = useTranslation('{count} stars', { count: 4 });
  const fiveStarsString = useTranslation('{count} stars', { count: 5 });

  const starsStrings = [
    oneStarString,
    twoStarsString,
    threeStarsString,
    fourStarsString,
    fiveStarsString,
  ];

  const starValue = 100 / numberOfStars;
  const ratingStars = Math.ceil(rating / starValue);

  return (
    <Wrapper>
      {makeIndexArray(numberOfStars).map((index) => (
        <Button
          key={index}
          onClick={() => onChange(starValue * (index + 1))}
          title={starsStrings[index]}
        >
          <StarIcon
            fill={index < ratingStars ? '#e0bc00' : '#d9ceb8'}
            color={index < ratingStars ? '#e0bc00' : '#d9ceb8'}
            size={16}
          />
        </Button>
      ))}
    </Wrapper>
  );
}
