import T from '@wojtekmaj/react-t';

import { Line, Small } from './marketing_disclaimers.styles';

import PrivacyPolicyLink from '../privacy_policy_link';

export default function MarketingDisclaimers() {
  return (
    <>
      <Line />
      <Small>
        <T link={<a href="mailto:kontakt@saladstory.com">kontakt@saladstory.com</a>}>
          {
            'You can withdraw your consent at any time. Withdrawal of consent does not affect the legality of the marketing information sent so far. To do this, write to us at {link} or log in and change the settings in your account.'
          }
        </T>
      </Small>
      <Small>
        <T link={<PrivacyPolicyLink />}>
          {
            'The administrator of your personal data is Salad Story S.A. with its registered office in Warsaw (02-222), Al. Jerozolimskie 179. Your personal data will be processed for the purpose of registering and managing your user account in the My Salad Story loyalty program, including sending you marketing messages related to your participation in the loyalty program (also using profiling), as well as for the statistical and analytical purposes of the administrator. In the event of expressing appropriate consent, your data will also be processed for the purpose of sending you marketing content in the form of e-mail, to the e-mail address provided. More information on the processing of personal data, including your rights, can be found in our {link}.'
          }
        </T>
      </Small>
    </>
  );
}
